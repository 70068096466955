import React, { useState } from "react";
import statusType from "./status";
import axios from "axios";
import styled from "@emotion/styled";
import loading from "./markers/loading.gif";

const StParagraph = styled("p")`
  margin: 0 0 0.2em 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
`;

const StButton = styled("button")`
  margin-top: 0.8em;
`;

const StButtonAllClear = styled(StButton)`
  background-color: rgb(117, 150, 0);

  &:hover {
    background-color: rgba(117, 150, 0, 85%);
  }
`;

const StButtonSnowedIn = styled(StButton)`
  background-color: rgb(14, 86, 152);

  &:hover {
    background-color: rgba(14, 86, 152, 85%);
  }
`;

const StImgWrapper = styled("div")`
  margin: 1.5em 6.5em;
`;

const REPORT = {
  REQUEST: "request",
  COMPLETE: "complete",
};
const InfoWindowContent = ({ item, callback }) => {
  const [submitting, setSubmitting] = useState(false);

  const { status, id } = item;

  return submitting ? (
    <StImgWrapper>
      <img src={loading} alt="" />
    </StImgWrapper>
  ) : (
    <>
      {status === statusType.SHOVELED ? (
        <>
          <StParagraph>
            This curb ramp has already been reported as cleared.
          </StParagraph>
          <StParagraph>Let us know if this is incorrect.</StParagraph>
          <StButtonSnowedIn
            className="action"
            onClick={() => {
              setSubmitting(true);
              axios
                .get(
                  `https://saferwintersidewalks.jasondelia.com/api/update.php?id=${id}&report=${REPORT.REQUEST}`
                )
                .then((res) => {
                  setSubmitting(false);
                  callback(res.data);
                });
            }}
          >
            Still Snowed In!
          </StButtonSnowedIn>
        </>
      ) : status === statusType.UNSHOVELED ? (
        <>
          <StParagraph>
            Looks like this curb ramp is still covered in snow.
          </StParagraph>
          <StParagraph>Let us know if it has been cleared.</StParagraph>
          <StButtonAllClear
            className="action"
            onClick={() => {
              setSubmitting(true);
              axios
                .get(
                  `https://saferwintersidewalks.jasondelia.com/api/update.php?id=${id}&report=${REPORT.COMPLETE}`
                )
                .then((res) => {
                  setSubmitting(false);
                  callback(res.data);
                });
            }}
          >
            All Clear!
          </StButtonAllClear>
        </>
      ) : (
        <>
          <StParagraph>
            We're not sure of the status of this curb ramp.
          </StParagraph>
          <StParagraph>Someone is checking.</StParagraph>
        </>
      )}
    </>
  );
};

export default InfoWindowContent;
