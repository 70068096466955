import React, { useEffect, useState } from "react";
import InfoWindowContent from "./InfoWindowContent";
import Header from "./Header";
import status from "./status";
import green from "./markers/green.png";
import red from "./markers/red.png";
import blue from "./markers/blue.png";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import axios from "axios";
import _ from "lodash";

const Map = () => {
  const [selected, setSelected] = useState({});
  const [showInfo, setShowInfo] = useState(false);
  const [center, setCenter] = useState({
    lat: 40.54729787946253,
    lng: -74.36718303127574,
  });
  const [zoom, setZoom] = useState(17);

  const onSelect = (item) => {
    setSelected(item);
    setCenter(item.location);
    setZoom(20);
  };
  const mapStyles = {
    // height: "100vh",
    // width: "100%",
    flex: "1",
  };

  const [apiLocations, setApiLocations] = useState([]);

  useEffect(() => {
    axios
      .get(`https://saferwintersidewalks.jasondelia.com/api/update.php`)
      .then((res) => {
        setApiLocations(res.data);
      });
  }, []);

  return (
    <LoadScript googleMapsApiKey="AIzaSyBeXLXPDosEZmm96OZjAOaz4_ZpbM8T00I">
      <Header setShowInfo={setShowInfo} showInfo={showInfo} />
      {!showInfo ? (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={zoom}
          center={center}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
          }}
        >
          {apiLocations.map((item) => {
            const formattedItem = {
              ...item,
              location: {
                lat: parseFloat(item.location.lat),
                lng: parseFloat(item.location.lng),
              },
            };
            const markerOptions = {
              opacity:
                _.isEmpty(selected) || selected.id === formattedItem.id
                  ? 1
                  : 0.5,
            };
            const icon = {
              [status.SHOVELED]: green,
              [status.UNSHOVELED]: red,
              [status.UNKNOWN]: blue,
            };
            return (
              <Marker
                key={formattedItem.name}
                position={formattedItem.location}
                onClick={() => onSelect(formattedItem)}
                icon={icon[formattedItem.status]}
                options={markerOptions}
              />
            );
          })}
          {selected.location && (
            <InfoWindow
              position={selected.location}
              clickable={true}
              onCloseClick={() => setSelected({})}
              options={{ pixelOffset: new window.google.maps.Size(0, -22) }}
            >
              <InfoWindowContent
                item={selected}
                callback={(data) => {
                  setApiLocations(data);
                  setSelected({});
                }}
              />
            </InfoWindow>
          )}
        </GoogleMap>
      ) : null}
    </LoadScript>
  );
};

export default Map;
