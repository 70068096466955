import React, { useState } from "react";
import styled from "@emotion/styled";

const StHeader = styled("header")`
  background-color: #002b52;
  color: #ecce9c;

  h1 {
    margin: 0;
    line-height: 3rem;
    font-size: 1.3em;
  }

  svg {
    fill: #ecce9c;
    stroke: #ecce9c;
    height: 2.5rem;
    float: right;
    margin: 0.25rem;
  }
`;

const StInfo = styled("div")`
  flex: 1;
  background: white;
  text-align: justify;
  overflow-y: auto;
`;

const StInfoContent = styled("div")`
  width: 70%;
  margin: 0 auto;
  padding: 1em;

  p {
    line-height: 1.8em;
  }
`;

const Header = ({ setShowInfo, showInfo }) => {
  return (
    <>
      <StHeader>
        {showInfo ? (
          <svg
            viewBox="0 0 100 100"
            onClick={() => {
              setShowInfo(false);
            }}
          >
            <g>
              <g>
                <path d="M51.4,86.3c-19.9,0-36.1-16.2-36.1-36.1C15.2,30.2,31.4,14,51.4,14s36.1,16.2,36.1,36.1C87.5,70.1,71.3,86.3,51.4,86.3z    M51.4,17c-18.3,0-33.1,14.9-33.1,33.1c0,18.3,14.9,33.1,33.1,33.1s33.1-14.9,33.1-33.1C84.5,31.9,69.6,17,51.4,17z"></path>
              </g>
              <g>
                <path d="M64.284,63.559c-1.631,1.631-4.272,1.631-5.903,0l-7.556-7.556l-7.57,7.57c-1.623,1.623-4.28,1.623-5.903,0    c-1.631-1.631-1.623-4.28,0-5.903l7.57-7.57l-7.57-7.57c-1.623-1.623-1.631-4.272,0-5.903c1.623-1.623,4.28-1.623,5.903,0    l7.57,7.57l7.556-7.556c1.631-1.631,4.272-1.631,5.903,0c1.623,1.623,1.631,4.272,0,5.903l-7.556,7.556l7.556,7.556    C65.914,59.287,65.907,61.936,64.284,63.559z"></path>
              </g>
            </g>
          </svg>
        ) : (
          <svg
            viewBox="0 0 100 100"
            onClick={() => {
              setShowInfo(true);
            }}
          >
            <g>
              <path d="M51.4,86.3c-19.9,0-36.1-16.2-36.1-36.1C15.2,30.2,31.4,14,51.4,14s36.1,16.2,36.1,36.1C87.5,70.1,71.3,86.3,51.4,86.3z    M51.4,17c-18.3,0-33.1,14.9-33.1,33.1c0,18.3,14.9,33.1,33.1,33.1s33.1-14.9,33.1-33.1C84.5,31.9,69.6,17,51.4,17z"></path>
            </g>
            <path d="M46,36.7c0-1.5,0.5-2.7,1.6-3.8c1.1-1,2.3-1.6,3.8-1.6c1.5,0,2.8,0.5,3.8,1.5c1,1,1.5,2.3,1.5,3.8c0,1.5-0.5,2.8-1.6,3.9  c-1.1,1.1-2.3,1.6-3.8,1.6c-1.5,0-2.7-0.5-3.8-1.6S46,38.2,46,36.7z"></path>
            <path d="M54.2,73h-5.7c-1.1,0-2.1-0.9-2.1-2.1V47.4c0-1.1,0.9-2.1,2.1-2.1h5.7c1.1,0,2.1,0.9,2.1,2.1v23.5  C56.3,72.1,55.3,73,54.2,73z"></path>
          </svg>
        )}
        <h1>Safer Winter Sidewalks</h1>
      </StHeader>
      {showInfo ? (
        <StInfo>
          <StInfoContent>
            <h2 style={{ textAlign: "center" }}>
              Welcome to the Metuchen Safer Winter Sidewalks Pilot
            </h2>
            <p>
              Moving throughout the Borough on snow covered sidewalks can be
              treacherous long after a storm has passed. Most of us do our part
              to clear the sidewalks in front of our homes and businesses,
              however, street corners can be particularity challenging. The
              typically large buildup of snow from plows clearing the streets
              can be difficult for many to properly shovel a path. Without a
              clear path at the curb ramps, sidewalks can become unnavigable for
              some.
            </p>

            <p>
              That is where you come in! If you are able, please “adopt” a
              corner or two in your area to clear of snow and make it passable.
              After your hard work is done, come back to this site to report it
              as “All Clear” so we can update the marker and let others know.
              Not able to shovel? You can still help by reporting corners you
              see that have already been cleared.
            </p>

            <p>
              We are starting this pilot in the Radio section with hopes of
              expanding it to the rest of the town. Please send your feedback
              and suggestions to{" "}
              <a href="mailto:jasonadelia@gmail.com">jasonadelia@gmail.com</a>.
            </p>
            <p>#OneMetuchen</p>
          </StInfoContent>
        </StInfo>
      ) : null}
    </>
  );
};
export default Header;
